import { BronsonButton, BronsonFormInput, BronsonSpinner } from '@dh/bronson-react';

import { getLabels } from './get.labels';
import { maxId, minId } from '../../utils/const';

import React, { useState } from 'react';

export const GetStorefront: React.FC<{
    isLoading: boolean;
    retrieveStorefront: (storefrontId: string) => void;
}> = (props) => {
    const { isLoading, retrieveStorefront } = props;
    const [storefrontId, setStorefrontId] = useState<string>('');

    return (
        <div className='' id='get-storefront'>
            <div className='o-layout__item u-1/1 u-p-none'>
                <div className='o-layout__item u-2/3 u-1/1@m u-1/1@s u-p-none'>
                    <BronsonFormInput
                        placeholder='b9c27cd4-8d1f-4d7b-93cb-0a3008f90d84'
                        elementId='storefront-input'
                        label={getLabels.form.storefrontId}
                        maxLength={maxId}
                        minLength={minId}
                        defaultValue={storefrontId}
                        onChange={(event) => setStorefrontId(event.value)}
                    />
                </div>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s' style={{ verticalAlign: 'bottom' }}>
                    {isLoading ? (
                        <div id='loading-get-spinner'>
                            <BronsonSpinner
                                config={{
                                    showSpinner: true,
                                    spinnerWrapperClassModifier: 'c-spinner--center',
                                }}
                            />
                        </div>
                    ) : (
                        <BronsonButton
                            dataComponent='get-storefront-btn'
                            label={getLabels.button.retrieve}
                            click={() => retrieveStorefront(storefrontId)}
                            config={{
                                isDisable: false,
                                types: ['isPrimary'],
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
