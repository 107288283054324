import { BronsonButton } from '@dh/bronson-react';
import { useEffect, useState } from 'react';

import { AppService } from '../../App.service';
import { CreateStorefront } from '../../components/Create';
import { GetStorefront } from '../../components/Get';
import { ResponseArea } from '../../components/ResponseArea';
import { StorefrontKind } from '../../utils/enum';
import {
    ACSBrandOptions,
    defaultFinancialProduct,
    defaultTrimLine,
    defaultVehicleModels,
    five,
    four,
    one,
    three,
    two,
    types,
    vehicleBrandOptions,
    zero,
} from '../../utils/const';
import {
    BasicInfo,
    DwaBrandResponse,
    FinancialProduct,
    StorefrontBodyRequest,
    TrimLine,
    TrimLineDetail,
    VehicleModel,
} from './home.interface';
import {
    Calculation,
    CustomerData,
    Dealer,
    PricingSimulationRequest,
    SalesCondition,
    VehicleData,
} from '../../utils/interface';
import {
    defaultBasicInfo,
    defaultCalculation,
    defaultCustomerData,
    defaultDealer,
    defaultDetail,
    defaultVehicleData,
    defaultVIN,
    HomeLabels,
} from './home.const';
import { documentationURL, stage } from '../../config';

export const Home = () => {
    const getUser = () => localStorage.getItem('user');
    const [user] = useState(getUser());
    useEffect(() => {
        if (!user) window.location.href = '#/auth';
    });

    const [brands, setBrands] = useState<Array<string>>(['']);
    const [bookingAmount, setBookingAmount] = useState<string | null>(null);
    const [calculation, setCalculation] = useState<Calculation>(defaultCalculation);
    const [customerData, setCustomerData] = useState<CustomerData>(defaultCustomerData);
    const [dealers, setDealers] = useState<Array<Dealer>>([defaultDealer]);
    const [financialProducts, setFinancialProducts] = useState<Array<FinancialProduct>>([
        defaultFinancialProduct,
    ]);
    const [kind, setKind] = useState<string>('');
    const [loadingVehicleModels, setLoadingVehicleModels] = useState<boolean>(false);
    const [loadingSummit, setLoadingSummit] = useState<boolean>(false);
    const [showCreateResponseArea, setShowResponseArea] = useState<boolean>(false);
    const [salesCondition, setSalesCondition] = useState<SalesCondition | null>();
    const [selectedDealer, setSelectedDealer] = useState<Dealer>(defaultDealer);
    const [selectedModel, setSelectedModel] = useState<BasicInfo>(defaultBasicInfo);
    const [selectedTab, setSelectedTab] = useState<string>('main');
    const [selectedTrimDetail, setSelectedTrimDetail] = useState<TrimLineDetail>(defaultDetail);
    const [selectedType, setSelectedType] = useState<string>('');
    const [summitDisabled, setSummitDisabled] = useState<boolean>(false);
    const [tabMainClass, setTabMainClass] = useState<string>('isPrimary');
    const [tabSecondaryClass, setTabSecondaryClass] = useState<string>('isSecondary');
    const [trimLines, setTrimLines] = useState<Array<TrimLine>>(defaultTrimLine);
    const [trimDetails, setTrimDetails] = useState<Array<TrimLineDetail>>([defaultDetail]);
    const [selectedVehicleData, setSelectedVehicleData] = useState<VehicleData>(defaultVehicleData);
    const [vehicleModels, setVehicleModels] = useState<Array<VehicleModel>>(defaultVehicleModels);
    const [vin, setVin] = useState<string>(defaultVIN);
    const [response, setResponse] = useState(<></>);
    const [isSuccessResponse, setIsSuccessResponse] = useState<boolean>(true);

    const setError = (error: any) => {
        setIsSuccessResponse(false);
        setShowResponseArea(true);
        setResponse(
            <div>
                <h4>{error.response.data.message}</h4>
                <p>{JSON.stringify(error)}</p>
            </div>,
        );
    };

    const handleBrandsByType = (value: string) => {
        onClear();
        setSelectedType(value);
        switch (value) {
            case types[one]:
                setKind(StorefrontKind.used);
                setBrands(vehicleBrandOptions);
                setSelectedVehicleData({ ...selectedVehicleData, brand: '', usedCar: true });
                break;
            case types[two]:
                setKind(StorefrontKind.new);
                setBrands(vehicleBrandOptions);
                const tempVD = { ...selectedVehicleData };
                if (tempVD.usedCar !== undefined) delete tempVD.usedCar;
                setSelectedVehicleData({ ...tempVD, brand: '' });
                break;
            case types[three]:
                setKind(StorefrontKind.used);
                setBrands(['', 'audi']);
                setSelectedVehicleData({ ...selectedVehicleData, brand: '', usedCar: true });
                break;
            case types[four]:
                setKind(StorefrontKind.new);
                setBrands(['', 'porsche']);
                setSelectedVehicleData({ ...selectedVehicleData, brand: '', usedCar: false });
                break;
            case types[five]:
                setKind(StorefrontKind.used);
                setBrands(ACSBrandOptions);
                setSelectedVehicleData({ ...selectedVehicleData, brand: '', usedCar: true });
                break;
            default:
                setKind('');
                setBrands(['']);
                break;
        }
    };

    const handleModelsByBrand = async (value: string) => {
        const appService = new AppService();
        setSelectedVehicleData({
            ...selectedVehicleData,
            brand: value,
        });
        try {
            let selectedBrand = value;
            if (kind === StorefrontKind.used && (value === 'vw' || value === 'seat')) {
                selectedBrand = 'dwa';
            }
            setLoadingVehicleModels(true);
            setSummitDisabled(true);
            const [response, responseDealers] = await Promise.all([
                appService.getVehicleModels(kind, selectedBrand),
                appService.getDealersByBrand(selectedBrand),
            ]);
            if (selectedBrand === 'dwa') {
                const brandName = value === 'vw' ? 'Volkswagen' : 'SEAT';
                const brandResponse = response.brandVehicleModels.find(
                    (brandVM: DwaBrandResponse) => brandVM.brandName === brandName,
                );
                setVehicleModels([...defaultVehicleModels, ...brandResponse.vehicleModels]);
            } else {
                setVehicleModels([...defaultVehicleModels, ...response.vehicleModels]);
            }
            setDealers([defaultDealer, ...responseDealers.dealers]);
            setShowResponseArea(false);
        } catch (error) {
            setError(error);
        }
        setSelectedDealer(defaultDealer);
        setSummitDisabled(false);
        setLoadingVehicleModels(false);
    };

    const setVehicleTrimLines = (value: string) => {
        const vehicleModel = vehicleModels.find((vehicleModel) => vehicleModel.vehicleModelName === value);
        if (vehicleModel) {
            setSelectedModel({
                id: vehicleModel.vehicleModelId,
                name: vehicleModel.vehicleModelName,
            });
            setTrimLines([...defaultTrimLine, ...vehicleModel.trimLines]);
        }
        setSelectedVehicleData({
            ...selectedVehicleData,
            modelYear: '',
        });
        setVehicleVersions('');
        setTrimDetails([defaultDetail]);
    };

    const setVehicleVersions = async (value: string) => {
        const trimLine = trimLines.find((trimL) => trimL.modelYear.toString() === value.toString());
        try {
            if (trimLine) {
                setSelectedVehicleData({
                    ...selectedVehicleData,
                    modelYear: trimLine.modelYear.toString(),
                });
                setTrimDetails([defaultDetail, ...trimLine.trimLineDetails]);
                if ([types[three], types[four]].includes(selectedType) && value !== '') {
                    const appService = new AppService();
                    setSummitDisabled(true);
                    const response = await appService.getFinancialProducts(
                        kind,
                        selectedVehicleData.brand,
                        selectedType === types[three] ? `${trimLine.modelYear}` : undefined,
                    );
                    const financialData = response.financialProducts;
                    financialData.pop();
                    setFinancialProducts([defaultFinancialProduct, ...financialData]);
                }
            }
            setShowResponseArea(false);
        } catch (error) {
            setError(error);
        }
        setSummitDisabled(false);
    };

    const setFormDealer = (value: string) => {
        const dealerValue = dealers.find((dealer) => dealer.dealerCode === value);
        if (dealerValue) {
            setSelectedDealer(dealerValue);
        }
    };

    const selectTrimDetail = (value: number) => {
        const trimDetail = trimDetails.find((trimD) => trimD.trimLineId === value);
        if (trimDetail) {
            trimDetail.retailPrice = Number(trimDetail.retailPrice) > zero ? trimDetail.retailPrice : null;
            setSelectedTrimDetail(trimDetail);
        }
    };

    const constructLink = (storefrontId: string, brand: string): string => {
        if (stage === 'prod') {
            switch (selectedType) {
                case types[one]:
                    return `https://preautorizacionfs.com/usados/?cid=${storefrontId}`;
                case types[two]:
                case types[three]:
                case types[four]:
                    return `https://${brand}.preautorizacionfs.com/?cid=${storefrontId}`;
                default:
                    return `https://preautorizacionfs.com/usados/acs/?cid=${storefrontId}`;
            }
        } else {
            switch (selectedType) {
                case types[one]:
                    return `https://vwfs.${stage}.mx.ventas.credit.vwfs.io/usados/?cid=${storefrontId}`;
                case types[two]:
                case types[three]:
                case types[four]:
                    return `https://${brand}.${stage}.mx.ventas.credit.vwfs.io/?cid=${storefrontId}`;
                default:
                    return `https://vwfs.${stage}.mx.ventas.credit.vwfs.io/usados/acs/?cid=${storefrontId}`;
            }
        }
    };

    const setCustomerInfo = (value, field) => {
        if (field === 'email' || field === 'telephoneNumber') {
            setCustomerData((prevVal) => ({
                ...prevVal,
                contactData: {
                    ...prevVal.contactData,
                    [field]: value,
                },
            }));
        } else {
            setCustomerData((prevVal) => ({
                ...prevVal,
                [field]: value,
            }));
        }
    };

    const retrievePricingSimulation = async (brand: string, pricingBody: PricingSimulationRequest) => {
        const appService = new AppService();
        const response = await appService.getPricingSimulation(brand, pricingBody);
        setShowResponseArea(false);
        return response;
    };

    const onClear = (create: boolean = false) => {
        setBookingAmount(null);
        setCalculation(defaultCalculation);
        setCustomerData(defaultCustomerData);
        setFinancialProducts([defaultFinancialProduct]);
        setSalesCondition(null);
        setSelectedDealer(defaultDealer);
        setSelectedModel(defaultBasicInfo);
        setSelectedTrimDetail(defaultDetail);
        setSelectedType('');
        setSelectedVehicleData(defaultVehicleData);
        setTrimLines(defaultTrimLine);
        setTrimDetails([defaultDetail]);
        setVehicleModels(defaultVehicleModels);
        if (!create) {
            setShowResponseArea(false);
            setResponse(<div></div>);
        }
    };

    const checkCustomerData = (createData: StorefrontBodyRequest) => {
        if (createData.customerData) {
            const keys = Object.keys(createData.customerData);
            let filled = false;
            for (const key of keys) {
                if (key === 'contactData') continue;
                if (createData.customerData[key]) filled = true;
            }
            if (!filled) delete createData.customerData;
        }
        if (!createData.customerData?.middleNames || createData.customerData?.middleNames === '') {
            delete createData.customerData?.middleNames;
        }
        if (!createData.customerData?.maternalName || createData.customerData?.maternalName === '') {
            delete createData.customerData?.maternalName;
        }
    };

    const validCustomerData = (customer: CustomerData): boolean => {
        const keys = Object.keys(customer);
        let valid = true;
        for (const key of keys) {
            if (key !== 'middleNames' && key !== 'maternalName' && customer[key] === '') {
                valid = false;
                break;
            }
        }
        if (customer.contactData.email === '' && customer.contactData.telephoneNumber === '') valid = false;
        return valid;
    };

    const onSummit = async () => {
        const createData = {
            customerData: { ...customerData },
            vehicleData: {
                ...selectedVehicleData,
                modelYear: Number(selectedVehicleData.modelYear),
                modelName: selectedModel.name,
                version: selectedTrimDetail.trimLineName,
                purchasePriceAmount: selectedTrimDetail.retailPrice,
            },
            dealerData: {
                address: selectedDealer.address,
                dealerCode: selectedDealer.dealerCode,
                dealerBrandCode: selectedDealer.dealerBrandCode,
                companyName: selectedDealer.companyName,
            },
            deletionPolicy: {
                deletionAfterDays: 30,
            },
        };
        if (
            (selectedType === types[two] && (!bookingAmount || !validCustomerData(customerData))) ||
            selectedTrimDetail.retailPrice === null
        ) {
            return setError({
                response: {
                    data: {
                        message: 'Missing required information for pre booking.',
                    },
                },
            });
        } else if (selectedType === types[two] && bookingAmount) {
            createData['financialProduct'] = {
                bookingData: { amount: +bookingAmount },
            };
        }
        if (vin && selectedType === types[five]) createData.vehicleData['vin'] = vin;
        if (salesCondition) {
            createData.vehicleData['modelId'] = selectedModel.id;
            createData.vehicleData['versionId'] = selectedTrimDetail.trimLineId;
            createData['financialProduct'] = {
                salesCondition,
                calculation,
            };
        }
        checkCustomerData(createData);
        const appService = new AppService();
        const isMFC = selectedType === types[four] || selectedType === types[three];
        try {
            setLoadingSummit(true);
            const result = await appService.createStorefront(
                kind,
                selectedVehicleData.brand,
                createData,
                isMFC,
            );
            setShowResponseArea(true);
            setIsSuccessResponse(true);
            const responseLink = constructLink(result.id, selectedVehicleData.brand);
            setResponse(
                <p>
                    {`${HomeLabels.response.successMessage} ${result.id}`}
                    <br />
                    {HomeLabels.response.labelLink}
                    <a className='u-base-link' href={responseLink} target='_blank' rel='noreferrer'>
                        {responseLink}
                    </a>
                </p>,
            );
            onClear(true);
        } catch (error) {
            setError(error);
        }
        setLoadingSummit(false);
    };

    const retrieveStorefront = async (storefrontId: string) => {
        const appService = new AppService();
        try {
            if (storefrontId === null || storefrontId === '') {
                setLoadingSummit(false);
                return setError({
                    response: {
                        data: {
                            message: 'Storefront Id mandatory.',
                        },
                    },
                });
            }
            setLoadingSummit(true);
            const result = await appService.getStorefront(storefrontId);
            setIsSuccessResponse(true);
            setShowResponseArea(true);
            const responseFormat = JSON.stringify(result, null, two);
            setResponse(
                <span>
                    <pre>{responseFormat}</pre>
                </span>,
            );
        } catch (error) {
            setLoadingSummit(false);
            setError(error);
        }
        setLoadingSummit(false);
    };

    const selectPageContent = (page: string) => {
        setSelectedTab(page);
        onClear();
        setShowResponseArea(false);
        if (page === 'main') {
            setTabMainClass('isPrimary');
            setTabSecondaryClass('isSecondary');
        } else {
            setTabMainClass('isSecondary');
            setTabSecondaryClass('isPrimary');
        }
    };

    return (
        <div className='o-page-wrap o-page-wrap--xsmall'>
            <div className='o-layout'>
                <div className='o-layout__item u-1/2 u-1/1@s' style={{ background: '#004666' }}>
                    <h3 className='u-text-white u-m-none u-p-xsmall'>{HomeLabels.title}</h3>
                </div>
                <div
                    className='o-layout__item u-1/1 u-text-white u-p-xsmall'
                    style={{ background: '#004666' }}
                >
                    Create Storefronts Id´s for the use cases of F&L MX application. For more information
                    about the use cases visit &nbsp;
                    <a className='u-text-white' href={documentationURL} target='_blank' rel='noreferrer'>
                        Functional Documentation & Use Cases
                    </a>
                </div>
                <div className='o-layout__item u-1/1 u-p-none'>
                    <div className='o-layout__item u-1/1 u-p-none u-mb-large'>
                        <div className='o-layout__item u-1/2 u-1/1@m u-1/1@s u-p-none'>
                            <BronsonButton
                                label={HomeLabels.buttons.main}
                                click={() => selectPageContent('main')}
                                config={{
                                    isDisable: false,
                                    types: [tabMainClass, 'isFull'],
                                }}
                            />
                        </div>
                        <div className='o-layout__item u-1/2 u-1/1@m u-1/1@s u-p-none'>
                            <BronsonButton
                                label={HomeLabels.buttons.get}
                                click={() => selectPageContent('get')}
                                config={{
                                    isDisable: summitDisabled,
                                    types: [tabSecondaryClass, 'isFull'],
                                }}
                            />
                        </div>
                    </div>
                    <>
                        {selectedTab === 'main' ? (
                            <CreateStorefront
                                brands={brands}
                                bookingAmount={bookingAmount}
                                customerData={customerData}
                                dealers={dealers}
                                financialProducts={financialProducts}
                                handleBrandsByType={handleBrandsByType}
                                handleModelsByBrand={handleModelsByBrand}
                                kind={kind}
                                loadingVehicleModels={loadingVehicleModels}
                                loadingSummit={loadingSummit}
                                onClear={onClear}
                                onSummit={onSummit}
                                retrievePricingSimulation={retrievePricingSimulation}
                                selectedCalculation={calculation}
                                selectedDealer={selectedDealer}
                                selectedTrimDetail={selectedTrimDetail}
                                selectedType={selectedType}
                                selectedVehicleData={selectedVehicleData}
                                selectTrimDetail={selectTrimDetail}
                                setBookingAmount={setBookingAmount}
                                setCalculation={setCalculation}
                                setCustomerInfo={setCustomerInfo}
                                setError={setError}
                                setFormDealer={setFormDealer}
                                setSelectedTrimDetail={setSelectedTrimDetail}
                                setSelectedVehicleData={setSelectedVehicleData}
                                setSalesCondition={setSalesCondition}
                                setVehicleTrimLines={setVehicleTrimLines}
                                setVehicleVersions={setVehicleVersions}
                                setVin={setVin}
                                summitDisabled={summitDisabled}
                                trimDetails={trimDetails}
                                trimLines={trimLines}
                                types={types}
                                vehicleModels={vehicleModels}
                                vin={vin}
                            />
                        ) : (
                            <GetStorefront
                                retrieveStorefront={retrieveStorefront}
                                isLoading={loadingSummit}
                            />
                        )}
                    </>
                    <div className='o-layout__item u-1/1 u-p-none u-mv'>
                        {showCreateResponseArea ? (
                            <ResponseArea isSuccess={isSuccessResponse}>{response}</ResponseArea>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
