import { AuthorizerForm } from '../../components/AuthorizerForm';
import { AuthorizerLabels } from './authorizer.labels';

import { AppService } from '../../App.service';

export const Authorizer = () => {
    const appService = new AppService();

    const requestCode = async (emailData: string) => {
        try {
            await appService.generateOTP(emailData);
        } catch (error) {
            throw error;
        }
    };

    const validateCode = async (emailData: string, code: string) => {
        try {
            await appService.validateOTP(emailData, code);
        } catch (error) {
            throw error;
        }
    };

    return (
        <section className='u-pv-xlarge'>
            <div className='o-page-wrap o-page-wrap--xsmall'>
                <section
                    className='o-content-section u-mb-xlarge u-text-white'
                    style={{ background: '#004666' }}
                >
                    <div className='o-page-wrap'>
                        <h4 className='u-text-white u-text-center'>{AuthorizerLabels.title}</h4>
                        <AuthorizerForm generateOTP={requestCode} validateOTP={validateCode} />
                    </div>
                </section>
            </div>
        </section>
    );
};
