import {
    BronsonButton,
    BronsonFormChangeEvent,
    BronsonFormInput,
    BronsonFormValidationsEnum,
    BronsonSpinner,
} from '@dh/bronson-react';

import { AuthorizerFormLabels } from './authorizer-form.labels';
import { four, maxEmail, one } from '../../utils/const';
import React, { useState } from 'react';

export const AuthorizerForm: React.FC<{
    generateOTP: (value: string) => Promise<void>;
    validateOTP: (emailData: string, code: string) => Promise<void>;
}> = (props) => {
    const { generateOTP, validateOTP } = props;

    const [code, setCode] = useState<string>('');
    const [emailData, setEmailData] = useState<string>('');
    const [emailErrorMsg, setEmailErrorMsg] = useState<string>(AuthorizerFormLabels.error.invalidEmail);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [showCodeInput, setShowCodeInput] = useState<boolean>(false);
    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const [validCode, setValidCode] = useState<boolean>(false);

    const validEmailExt = (email: string): boolean => {
        const atChar = email.search('@');
        const ext = email.substring(atChar + one);
        const validExts = ['vwfs.com', 'vwfs.io'];
        if (validExts.includes(ext)) {
            setEmailError(false);
            return true;
        }
        setEmailError(true);
        setEmailErrorMsg(AuthorizerFormLabels.error.invalidExtension);
        return false;
    };

    const getEmail = (event: BronsonFormChangeEvent) => {
        const value = event.value;
        if (event.hasError) {
            setEmailErrorMsg(AuthorizerFormLabels.error.invalidEmail);
            setValidEmail(false);
        } else if (validEmailExt(value)) setValidEmail(true);
        setEmailData(value);
    };

    const getCode = (event: BronsonFormChangeEvent) => {
        const value = event.value;
        setShowErrorMsg(false);
        if (event.hasError) {
            setValidCode(false);
        } else if (value.length < four) setValidCode(false);
        else setValidCode(true);
        setCode(value);
    };

    const requestCode = async () => {
        setLoading(true);
        try {
            await generateOTP(emailData);
            setShowCodeInput(true);
            setShowErrorMsg(false);
        } catch (error) {
            setShowCodeInput(false);
            setShowErrorMsg(true);
            setErrorMsg(AuthorizerFormLabels.error.requestCode);
        }
        setLoading(false);
    };

    const validateCode = async () => {
        setLoading(true);
        try {
            await validateOTP(emailData, code);
            localStorage.setItem('user', emailData);
            window.location.href = '/';
        } catch (error) {
            setShowErrorMsg(true);
            setErrorMsg(AuthorizerFormLabels.error.invalidCode);
        }
        setLoading(false);
    };

    return (
        <div className='o-layout u-text-white' id='authorizer-form'>
            {!showCodeInput ? (
                <div className='o-layout__item u-1/1'>
                    <div className='u-pb-xsmall'>{AuthorizerFormLabels.form.email}</div>
                    <BronsonFormInput
                        defaultValue={emailData}
                        placeholder={AuthorizerFormLabels.form.placeholderEmail}
                        elementId='auth-mail-input'
                        errorMessage={emailErrorMsg}
                        hasError={emailError}
                        maxLength={maxEmail}
                        validations={[BronsonFormValidationsEnum.isEmail]}
                        onChange={(event) => getEmail(event)}
                    >
                        <button
                            className='c-input__addon'
                            type='button'
                            aria-label='search'
                            onClick={() => requestCode()}
                            id='request-code-btn'
                        >
                            <i className='c-icon  c-icon--[semantic-mail]' aria-hidden='true' role='img'></i>
                        </button>
                    </BronsonFormInput>
                </div>
            ) : null}

            <div className='o-layout__item u-1/1'>{!showCodeInput ? <></> : null}</div>
            {showCodeInput ? (
                <div className='u-text-center'>
                    <div className='o-layout__item u-1/1'>
                        <div className='u-pb-xsmall'>{AuthorizerFormLabels.form.code}</div>
                        <div className='o-layout__item u-1/4 u-1/1@s'>
                            <BronsonFormInput
                                defaultValue={code}
                                placeholder={AuthorizerFormLabels.form.placeholderCode}
                                elementId='auth-code-input'
                                maxLength={four}
                                validations={[BronsonFormValidationsEnum.isNumber]}
                                onChange={(event) => getCode(event)}
                            />
                        </div>
                    </div>
                    <div className='o-layout__item u-1/1 u-pt'>
                        <div className='o-layout__item u-1/1 o-layout--center'>
                            <div className='o-layout__item u-1/2 u-text-right'>
                                <BronsonButton
                                    label='Back'
                                    dataComponent='back-btn'
                                    click={() => {
                                        setShowCodeInput(false);
                                        setShowErrorMsg(false);
                                    }}
                                    config={{
                                        isDisable: false,
                                        types: ['isPrimary'],
                                        elementClassModifier: 'u-ph-large',
                                    }}
                                />
                            </div>
                            <div className='o-layout__item u-1/2'>
                                <BronsonButton
                                    label={AuthorizerFormLabels.buttons.sendCode}
                                    dataComponent='validate-code-btn'
                                    click={() => validateCode()}
                                    config={{
                                        isDisable: !validEmail && !validCode,
                                        types: ['isPrimary'],
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {loading ? (
                <BronsonSpinner
                    config={{
                        showSpinner: true,
                        spinnerWrapperClassModifier: 'c-spinner--full-page',
                    }}
                />
            ) : (
                <></>
            )}

            {showErrorMsg ? (
                <div className='o-layout__item u-1/1 u-text-center u-pt'>
                    <div
                        id='error-area'
                        className='o-layout o-layout__item u-1/2 u-1/2@m u-1/1@s u-text-center'
                    >
                        <p style={{ color: '#ff0000' }}>{errorMsg}</p>
                    </div>
                </div>
            ) : null}

            {!showCodeInput ? (
                <div className='o-layout__item u-1/1'>
                    <div className='o-layout o-layout__item u-1/1 u-text-center'>
                        <BronsonButton
                            dataComponent='show-code-btn'
                            label={AuthorizerFormLabels.buttons.showCodeInput}
                            click={() => setShowCodeInput(true)}
                            config={{
                                isDisable: false,
                                types: [],
                            }}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};
