import React from 'react';
import './style.css';

export const ResponseArea: React.FC<{ isSuccess: boolean }> = (props) => (
    <div
        id='response-area'
        className={`o-layout__item u-1/1 u-1/1@m u-1/1@s u-p ${props.isSuccess ? 'success' : 'error'}`}
    >
        {props.children}
    </div>
);
