export const defaultDetail = {
    driverTrain: '',
    retailPrice: null,
    transmission: '',
    trimLineId: 0,
    trimLineName: '',
};

export const defaultTrimLine = [
    {
        modelYear: '',
        trimLineDetails: [defaultDetail],
    },
];

export const types = ['', 'Mercado Libre', 'Pre booking', 'VTP', 'Porsche Web integration', 'ACS (e-used)'];

export const vehicleBrandOptions = ['', 'vw', 'seat'];

export const ACSBrandOptions = [...vehicleBrandOptions, 'audi'];

export const defaultProductDuration = {
    commissionPercentageCharge: 0,
    durationMonths: '',
    financialProductDurationId: 0,
    interestRatePercentage: 0,
    maximumDownPaymentPercentage: 0,
    minimumDownPaymentPercentage: 0,
};

export const defaultFinancialProduct = {
    financialProductId: 0,
    financialProductName: '',
    financialProductTypeId: 0,
    summary: '',
    productDurations: [defaultProductDuration],
};

export const defaultVehicleModels = [
    {
        vehicleModelId: 0,
        vehicleModelName: '',
        trimLines: defaultTrimLine,
    },
];
