import { StorefrontBodyResponse } from './home.interface';
import { Calculation, CustomerData } from '../../utils/interface';

export const HomeLabels = {
    buttons: {
        main: 'Create',
        get: 'Search',
    },
    response: {
        labelLink: 'Storefront link: ',
        successMessage: 'Storefront created with Id: ',
    },
    title: 'Storefront Self Service',
};

export const defaultBasicInfo = {
    name: '',
};

export const defaultVIN = '2G1WF52E4Y9151870';

export const defaultDetail = {
    driverTrain: '',
    retailPrice: null,
    transmission: '',
    trimLineId: 0,
    trimLineName: '',
};

export const defaultCalculation: Calculation = {
    durationUnit: 'MONTHS',
    duration: 0,
    durationId: 0,
    monthlyAmount: 0,
    downPaymentAmount: 0,
    downPaymentPercentage: null,
};

export const defaultCustomerData: CustomerData = {
    firstName: '',
    middleNames: null,
    paternalName: '',
    maternalName: null,
    dateOfBirth: '',
    contactData: {
        email: '',
        telephoneNumber: '',
    },
};

export const storefrontDataDefault: StorefrontBodyResponse = {
    id: '1',
    creationDate: '',
    customerData: { ...defaultCustomerData },
    financialProduct: {
        bookingData: {
            amount: 0,
        },
    },
    vehicleData: {
        brand: '',
        modelName: '',
        modelYear: '',
        version: '',
        purchasePriceAmount: 0,
    },
    dealerData: {
        address: {
            city: '',
            municipality: '',
            municipalityId: '',
            neighborhood: '',
            postalCode: '',
            state: '',
            stateId: '',
            street: '',
        },
        dealerCode: '',
        dealerBrandCode: '',
        companyName: '',
    },
    deletionPolicy: {
        deletionAfterDays: 0,
    },
};

export const defaultDealer = {
    address: {
        city: '',
        municipality: '',
        municipalityId: '',
        neighborhood: '',
        postalCode: '',
        state: '',
        stateId: '',
        street: '',
    },
    companyName: '',
    dealerBrand: '',
    dealerBrandCode: '',
    dealerCode: '',
    dealerId: '',
    dealership: {
        dealershipName: '',
        dealershipPhone: '',
        dealershipSalesContractEmail: '',
    },
    hasDWA: true,
    hasLCV: true,
    fullName: '',
    searchFullName: '',
};

export const defaultVehicleData = {
    brand: '',
    modelName: '',
    modelYear: '',
    version: '',
    purchasePriceAmount: null,
    imageUrl: 'https://http2.mlstatic.com/D_NQ_NP_831885-MLM50496105280_062022-W.webp',
};
