export const CreateLabels = {
    buttons: {
        clear: 'Clear',
        summit: 'Create',
    },
    form: {
        brand: 'Brand',
        bookingAmount: 'Booking Amount',
        dealer: 'Dealer',
        downpaymentAmount: 'Downpayment Amount',
        downpaymentPercentage: 'Downpayment Percentage',
        financialProducts: 'Financial Product',
        image: 'Image Link',
        monthlyAmount: 'Monthly Amount',
        price: 'Price',
        productDuration: 'Product Duration',
        type: 'Use Case',
        vehicleModel: 'Vehicle Model',
        version: 'Vehicle Version',
        vin: 'VIN',
        year: 'Year',
    },
};
