export const getLabels = {
    button: {
        retrieve: 'Buscar',
    },
    form: {
        isMFC: 'Is MFC?',
        storefrontId: 'Storefront Id',
        type: 'Tipo',
    },
};
