export const AuthorizerFormLabels = {
    buttons: {
        again: 'Request another code',
        reqCode: 'Request code',
        sendCode: 'Send code',
        showCodeInput: 'If you already have a code, click here',
    },
    form: {
        code: 'Verify code',
        email: 'Request access',
        placeholderEmail: 'Write your email to receive an access code',
        placeholderCode: '1234',
    },
    error: {
        requestCode: 'Error when trying to request OTP code.',
        invalidCode: 'The provided code is invalid.',
        invalidEmail: 'Please introduce a valid email.',
        invalidExtension:
            'The  provided email must have one of the following valid extensions: vwfs.io or vwfs.com.',
    },
};
