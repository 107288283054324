import axios from 'axios';

import { clientConfig } from './config';
import { PricingSimulationRequest, PricingSimulationResponse } from './utils/interface';
import { StorefrontBodyRequest, StorefrontBodyResponse } from './Views/Home/home.interface';

export class AppService {
    public async getDealersByBrand(brand: string) {
        const response = await axios.get(`${clientConfig.serviceUrl}/dealers/${brand}`);
        return response.data;
    }

    public async getFinancialProducts(type: string, brand: string, modelYear?: string): Promise<any> {
        const response = await axios.get(
            `${clientConfig.serviceUrl}/product-pricing/financial-products/${brand}/${type}?hasPA=true` +
                `${modelYear ? `&modelYear=${modelYear}` : ''}`,
        );
        return response.data;
    }

    public async generateOTP(email: string) {
        const response = await axios.post(`${clientConfig.serviceUrl}/otp/generate`, { email });
        return response.data;
    }

    public async getPricingSimulation(
        brand: string,
        body: PricingSimulationRequest,
    ): Promise<PricingSimulationResponse> {
        const response = await axios.post<PricingSimulationResponse>(
            `${clientConfig.serviceUrl}/product-pricing/pricing/simulation/${brand}?hasPA=true`,
            body,
        );
        return response.data;
    }

    public async getStorefront(storefrontId: string): Promise<StorefrontBodyResponse> {
        const response = await axios.get<StorefrontBodyResponse>(
            `${clientConfig.serviceUrl}/autocredit/new/data/${storefrontId}`,
        );
        return response.data;
    }

    public async getVehicleModels(type: string, brand: string): Promise<any> {
        const response = await axios.get(
            `${clientConfig.serviceUrl}/product-pricing/vehicle-models/${brand}/${type}?hasPA=true`,
        );
        return response.data;
    }

    public async createStorefront(
        type: string,
        brand: string,
        body: StorefrontBodyRequest,
        isMFC = false,
    ): Promise<any> {
        const response = await axios.post(
            `${clientConfig.serviceUrl}/autocredit/${type}/${brand}${isMFC ? '/mfc' : ''}`,
            body,
        );
        return response.data;
    }

    public async validateOTP(email: string, otpCode: string) {
        const response = await axios.post(`${clientConfig.serviceUrl}/otp/validate`, { email, otpCode });
        return response.data;
    }
}
