export const customerFormLabels = {
    form: {
        firstName: 'First Name',
        middleName: 'Middle Name(s)',
        paternalName: 'Last Name',
        maternalName: 'Second Last Name',
        dateOfBirth: 'Date of Birth',
        email: 'E-mail',
        telephoneNumber: 'Phone Number',
    },
    title: 'Customer Data',
};
