import { Link } from 'react-router-dom';
import {
    BronsonFooter,
    BronsonFooterProps,
    BronsonMegaMenu,
    BronsonNavigationLink,
    BronsonSpinner,
    BronsonSpinnerProps,
} from '@dh/bronson-react';
import React, { useState } from 'react';

import { BronsonAppLabels } from './bronson-app.label';
import { Home } from '../../Views/Home';

export const BronsonApp: React.FC<{
    userData?;
    loading?: boolean;
}> = (props) => {
    // Description of user
    const user: { given_name: string } = { given_name: props.userData };
    // Menu state
    const [logoutMenuOpen, setLogoutMenuOpen] = useState(false);

    /*
     * +-+-+-+-+-+-+-+-+
     * |H|a|n|d|l|e|r|s|
     * +-+-+-+-+-+-+-+-+
     */
    /**
     * Spinner configuration definition
     */
    const spinnerConfig: BronsonSpinnerProps = {
        showSpinner: true,
        spinnerWrapperClassModifier: 'c-spinner--text',
        showSpinnerText: true,
        spinnerText: 'Estamos configurando tu espacio de trabajo',
    };

    /**
     * Close logout navbar menu
     */
    const closeLogoutMenu = () => {
        setLogoutMenuOpen(!logoutMenuOpen);
    };

    /**
     * Get Footer configurations for links
     */
    const getFooter = (): BronsonFooterProps => {
        return {
            copyRight: 'Volkswagen Financial Services MX 2024',
            note:
                '* "Volkswagen Financial Services, The Key to Mobility", ' +
                'significa en español "Volkswagen Servicios Financieros, La Llave de la Movilidad".',
        };
    };

    /**
     * This method is a generic generator for menu items
     * @param iconVar icon variable
     * @param menuStatus boolean with the current status of menu
     * @param menuController function to handle state
     * @returns menu icon with controller
     */
    const menuControl = (
        id: string,
        iconVar: string,
        menuStatus: boolean,
        menuController: () => void,
        menuLabel?: JSX.Element,
    ): JSX.Element => {
        return (
            <button
                id={id}
                className={`c-page-head-nav__link ${menuStatus ? 'is-open' : ''}`}
                onClick={menuController}
            >
                <span className='c-page-head-nav__link-text u-hide@s'>{menuLabel}</span>
                <i className={`c-icon c-icon--small c-icon--[${iconVar}] c-page-head-nav__link-icon`}></i>
            </button>
        );
    };

    /**
     * Get Route definition of the home page to display Dashboard link
     */
    const getHomePage = () => {
        //const homePage = PortalRoutes.filter((elem) => elem.path === '/')[0];
        const homePage = {
            component: Home,
            path: '/',
            exact: true,
            label: '',
        };
        return <BronsonNavigationLink key={0} route={homePage} />;
    };

    /**
     * User label menu
     */
    const userLabel = <span className='u-m-xsmall'>{user.given_name}</span>;
    /**
     * Menu label profile
     */
    const showHeaderLink = () => {
        return (
            <div>
                <Link id='app-home-control' to='/'>
                    <img
                        className='c-brand-logo c-brand-logo--large-screen u-p-none'
                        src={'https://cdn.bronson.vwfs.tools/bluelabel/v/8.0.0/img/logo.svg'}
                        alt='logo'
                    />
                </Link>
            </div>
        );
    };

    /*
     * +-+-+-+-+ +-+-+-+-+-+-+
     * |M|a|i|n| |R|e|n|d|e|r|
     * +-+-+-+-+ +-+-+-+-+-+-+
     */

    if (props.loading) {
        return (
            <div>
                <BronsonSpinner config={spinnerConfig}></BronsonSpinner>
                {/* Even if the component is loading props.children should be render to allow redirection functionality, 
                    for example on log out */}
                <div className='u-hide'>{props.children}</div>
            </div>
        );
    } else {
        return (
            <div>
                {/* Site Header */}
                <header className='c-header__wrapper'>
                    <div id='app-navigation-bar' className='c-header c-header--static'>
                        <div className='o-page-wrap'>
                            <div className='c-page-head__inner'>
                                {showHeaderLink()}
                                <ul className='c-page-head-nav'>
                                    {getHomePage()}
                                    {props.userData ? (
                                        <div className='c-page-head-nav__item' style={{ marginLeft: 'auto' }}>
                                            <li className='c-page-head-nav__item' id='user-menu'>
                                                {menuControl(
                                                    'user-menu-control',
                                                    'control-user',
                                                    logoutMenuOpen,
                                                    closeLogoutMenu,
                                                    userLabel,
                                                )}
                                            </li>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </ul>
                            </div>
                            <BronsonMegaMenu
                                routes={[
                                    {
                                        label: BronsonAppLabels.logOut,
                                        img: 'control-logout',
                                        onClick: () => {
                                            localStorage.removeItem('user');
                                            window.location.href = '/';
                                        },
                                    },
                                ]}
                                disabled={!logoutMenuOpen}
                                onCloseMenu={closeLogoutMenu}
                            />
                        </div>
                    </div>
                </header>

                {/* App Wrapped Content */}
                <main className='o-main'>{props.children}</main>

                {/* App Footer */}
                <BronsonFooter {...getFooter()} />
            </div>
        );
    }
};
