import {
    BronsonButton,
    BronsonFormInput,
    BronsonFormSelect,
    BronsonFormValidationsEnum,
    BronsonSpinner,
} from '@dh/bronson-react';

import { CreateLabels } from './create.labels';
import { CustomerForm } from '../CustomerForm';
import { StorefrontKind } from '../../utils/enum';
import {
    Calculation,
    CustomerData,
    Dealer,
    PricingSimulationRequest,
    PricingSimulationResponse,
    SalesCondition,
    VehicleData,
} from '../../utils/interface';
import {
    defaultProductDuration,
    five,
    maxId,
    maxImage,
    maxInput,
    one,
    oneHundred,
    two,
    zero,
} from '../../utils/const';
import {
    FinancialProduct,
    ProductDuration,
    TrimLine,
    TrimLineDetail,
    VehicleModel,
} from '../../Views/Home/home.interface';

import React, { useState } from 'react';

export const CreateStorefront: React.FC<{
    brands: Array<string>;
    bookingAmount: string | null;
    customerData: CustomerData;
    dealers: Array<Dealer>;
    financialProducts: Array<FinancialProduct>;
    handleBrandsByType: (value: string) => void;
    handleModelsByBrand: (value: string) => void;
    kind: string;
    loadingVehicleModels: boolean;
    loadingSummit: boolean;
    onClear: () => void;
    onSummit: () => Promise<void>;
    retrievePricingSimulation: (
        brand: string,
        pricingBody: PricingSimulationRequest,
    ) => Promise<PricingSimulationResponse>;
    selectedCalculation: Calculation;
    selectedDealer: Dealer;
    selectedTrimDetail: TrimLineDetail;
    selectedType: string;
    selectedVehicleData: VehicleData;
    selectTrimDetail: (value: number) => void;
    setBookingAmount: (value: string) => void;
    setCalculation: (value: Calculation) => void;
    setCustomerInfo: (value: string, field: string) => void;
    setError: (error: any) => void;
    setFormDealer: (value: string) => void;
    setSelectedTrimDetail: (value: TrimLineDetail) => void;
    setSalesCondition: (value: SalesCondition) => void;
    setSelectedVehicleData: (value: VehicleData) => void;
    setVehicleTrimLines: (value: string) => void;
    setVehicleVersions: (value: string) => void;
    setVin: (value: string) => void;
    summitDisabled: boolean;
    trimLines: Array<TrimLine>;
    types: Array<string>;
    vehicleModels: Array<VehicleModel>;
    vin: string;
    trimDetails?: Array<TrimLineDetail>;
}> = (props) => {
    const {
        brands,
        bookingAmount,
        customerData,
        dealers,
        financialProducts,
        handleBrandsByType,
        handleModelsByBrand,
        kind,
        loadingVehicleModels,
        loadingSummit,
        onClear,
        onSummit,
        retrievePricingSimulation,
        selectedCalculation,
        selectedDealer,
        selectedTrimDetail,
        selectedType,
        selectedVehicleData,
        selectTrimDetail,
        setBookingAmount,
        setCalculation,
        setCustomerInfo,
        setError,
        setFormDealer,
        setSalesCondition,
        setSelectedTrimDetail,
        setSelectedVehicleData,
        setVehicleTrimLines,
        setVehicleVersions,
        setVin,
        summitDisabled,
        trimDetails,
        trimLines,
        types,
        vehicleModels,
        vin,
    } = props;

    const [pricingInfo, setPricingInfo] = useState({
        financialProductDurationId: 0,
        vehicleModelTrimLineId: 0,
    });
    const [altDisabled, setAltDisabled] = useState<boolean>(false);
    const [downpaymentPercentage, setDownpaymentPercentage] = useState<number | null>(null);
    const [minimumDownpaymentPercentage, setMinimumDownpaymentPercentage] = useState<number>(zero);
    const [maximumDownpaymentPercentage, setMaximumDownpaymentPercentage] = useState<number>(zero);
    const [productDurations, setProductDurations] = useState<Array<ProductDuration>>([
        defaultProductDuration,
    ]);
    const [invalidPercentage, setInvalidPercentage] = useState<boolean>(false);
    const [invalidPrice, setInvalidPrice] = useState<boolean>(false);
    const [invalidBookingAmount, setInvalidBookingAmount] = useState<boolean>(false);

    const selectFinancialProduct = (financialId: string) => {
        console.log('financialProducts', financialProducts);
        const fp = financialProducts.find(
            (financialProd) => financialProd.financialProductId === +financialId,
        );
        if (fp) {
            setProductDurations([defaultProductDuration, ...fp.productDurations]);
            setSalesCondition({
                productId: `${fp.financialProductId}`,
                productName: fp.financialProductName,
            });
        }
    };

    const selectProductDuration = (prodDurationId: number) => {
        const pd = productDurations.find(
            (prodDuration) => prodDuration.financialProductDurationId === prodDurationId,
        );
        if (pd) {
            setPricingInfo({
                ...pricingInfo,
                financialProductDurationId: pd.financialProductDurationId,
            });
            setMinimumDownpaymentPercentage(pd.minimumDownPaymentPercentage);
            setMaximumDownpaymentPercentage(pd.maximumDownPaymentPercentage);
            setCalculation({
                ...selectedCalculation,
                duration: Number(pd.durationMonths),
                durationId: pd.financialProductDurationId,
            });
        }
    };

    const setImageUrl = (value: string) =>
        setSelectedVehicleData({
            ...selectedVehicleData,
            imageUrl: value,
        });

    const setVehicleVersion = (trimDetail: number) => {
        selectTrimDetail(trimDetail);
        console.log('trimDetail', trimDetail);
        console.log('pricingInfo', pricingInfo);
        setPricingInfo({
            ...pricingInfo,
            vehicleModelTrimLineId: trimDetail,
        });
    };

    const roundNumber = (amount: number) => parseFloat(amount.toFixed(two));

    const setDownpaymentInfo = async (downpaymentValue: number) => {
        try {
            if (
                downpaymentValue >= minimumDownpaymentPercentage &&
                downpaymentValue <= maximumDownpaymentPercentage
            ) {
                const roundPrice = roundNumber(Number(selectedTrimDetail.retailPrice));
                const percentageDecimal = downpaymentValue / oneHundred;
                const downpaymentAmount = roundNumber(roundPrice * percentageDecimal);
                const pricingBody = {
                    ...pricingInfo,
                    downPaymentPercentage: downpaymentValue,
                    retailPrice: selectedTrimDetail.retailPrice as number,
                };
                if (selectedVehicleData.brand === 'audi') {
                    pricingBody['modelYear'] = `${selectedVehicleData.modelYear}`;
                }
                setAltDisabled(true);
                setInvalidPercentage(false);
                const response = await retrievePricingSimulation(selectedVehicleData.brand, pricingBody);
                setCalculation({
                    ...selectedCalculation,
                    downPaymentPercentage: percentageDecimal,
                    downPaymentAmount: downpaymentAmount,
                    monthlyAmount: response.installmentAmount,
                });
                setDownpaymentPercentage(downpaymentValue);
            } else {
                setInvalidPercentage(true);
            }
        } catch (error) {
            setError(error);
        }
        setAltDisabled(false);
    };

    const setErrorMsg = () =>
        `The value doesn't matches the minimum ${minimumDownpaymentPercentage}` +
        ` and maximum ${maximumDownpaymentPercentage} value.`;

    const setPriceErrorMsg = () => 'The price value must be a number bigger than 0.';

    const spinnerComponent = (
        <BronsonSpinner
            config={{
                showSpinner: true,
                spinnerWrapperClassModifier: 'c-spinner--center u-mt-small',
            }}
        />
    );

    const setClear = () => {
        setDownpaymentPercentage(null);
        onClear();
    };

    const setSummit = () => {
        setDownpaymentPercentage(null);
        onSummit();
    };

    const setSelectedPrice = (value: number) => {
        if (value > zero || value === null) {
            setInvalidPrice(false);
            setSelectedTrimDetail({
                ...selectedTrimDetail,
                retailPrice: value,
            });
        } else {
            setInvalidPrice(true);
        }
    };

    const validateBookingAmount = (value: string) => {
        if (+value > zero) {
            setInvalidBookingAmount(false);
        } else {
            setInvalidBookingAmount(true);
        }
        setBookingAmount(value);
    };

    const SelectBrand = (
        <BronsonFormSelect
            defaultValue={selectedVehicleData.brand}
            label={CreateLabels.form.brand}
            elementId='select-brand'
            onChange={(event) => handleModelsByBrand(event.value)}
            required={true}
            validations={[BronsonFormValidationsEnum.required]}
            isDisabled={summitDisabled || altDisabled}
        >
            {brands &&
                brands.map((brand, i) => (
                    <option key={i} value={brand}>
                        {brand}
                    </option>
                ))}
        </BronsonFormSelect>
    );

    const selectType = (
        <BronsonFormSelect
            defaultValue={selectedType}
            label={CreateLabels.form.type}
            elementId='select-type'
            onChange={(event) => handleBrandsByType(event.value)}
            required={true}
            validations={[BronsonFormValidationsEnum.required]}
            isDisabled={summitDisabled || altDisabled}
        >
            {types.map((type, i) => (
                <option key={i} value={type}>
                    {type}
                </option>
            ))}
        </BronsonFormSelect>
    );

    const selectVehicleModels = (
        <BronsonFormSelect
            defaultValue={''}
            label={CreateLabels.form.vehicleModel}
            elementId='select-vehicle-model'
            onChange={(event) => setVehicleTrimLines(event.value)}
            validations={[BronsonFormValidationsEnum.required]}
            isDisabled={summitDisabled || altDisabled}
        >
            {vehicleModels.map((vehicleModel, i) => (
                <option key={i} value={vehicleModel.vehicleModelName}>
                    {vehicleModel.vehicleModelName}
                </option>
            ))}
        </BronsonFormSelect>
    );

    const selectYear = (
        <BronsonFormSelect
            defaultValue={selectedVehicleData.modelYear}
            elementId='select-year'
            label={CreateLabels.form.year}
            onChange={(event) => setVehicleVersions(event.value)}
            validations={[BronsonFormValidationsEnum.required]}
            isDisabled={summitDisabled || altDisabled}
        >
            {trimLines.map((trimLine, i) => (
                <option key={i} value={trimLine.modelYear}>
                    {trimLine.modelYear}
                </option>
            ))}
        </BronsonFormSelect>
    );

    const selectVersion = (
        <BronsonFormSelect
            defaultValue={''}
            elementId='select-version'
            label={CreateLabels.form.version}
            onChange={(event) => setVehicleVersion(+event.value)}
            validations={[BronsonFormValidationsEnum.required]}
            isDisabled={summitDisabled || altDisabled}
        >
            {trimDetails?.map((trimDetail, i) => (
                <option key={i} value={trimDetail.trimLineId}>
                    {trimDetail.trimLineName}
                </option>
            ))}
        </BronsonFormSelect>
    );

    const selectDealer = (
        <BronsonFormSelect
            defaultValue={selectedDealer.dealerCode}
            label={CreateLabels.form.dealer}
            elementId='select-dealer'
            onChange={(event) => setFormDealer(event.value)}
            validations={[BronsonFormValidationsEnum.required]}
            isDisabled={summitDisabled || altDisabled}
        >
            {dealers?.map((dealer, i) => (
                <option key={i} value={dealer.dealerCode}>
                    {`${dealer.dealerCode} - ${dealer.dealership.dealershipName}`}
                </option>
            ))}
        </BronsonFormSelect>
    );

    const financialProductSection = (
        <>
            <div className='o-layout__item u-1/1 u-pt-small u-pl-none'>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s u-pl-none'>
                    <BronsonFormSelect
                        elementId='financial-product-select'
                        defaultValue={''}
                        label={CreateLabels.form.financialProducts}
                        onChange={(event) => selectFinancialProduct(event.value)}
                        validations={[BronsonFormValidationsEnum.required]}
                        isDisabled={summitDisabled || altDisabled}
                    >
                        {financialProducts.map((financialProd) => (
                            <option
                                value={financialProd.financialProductId}
                                key={financialProd.financialProductId}
                            >
                                {financialProd.financialProductName}
                            </option>
                        ))}
                    </BronsonFormSelect>
                </div>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s'>
                    <BronsonFormSelect
                        elementId='product-duration-select'
                        label={CreateLabels.form.productDuration}
                        defaultValue={''}
                        onChange={(event) => selectProductDuration(+event.value)}
                        validations={[BronsonFormValidationsEnum.required]}
                        isDisabled={summitDisabled || altDisabled}
                    >
                        {productDurations.map((productDuration, i) => (
                            <option key={i} value={productDuration.financialProductDurationId}>
                                {productDuration.durationMonths}
                            </option>
                        ))}
                    </BronsonFormSelect>
                </div>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s'>
                    <BronsonFormInput
                        elementId='down-payment-percentage-input'
                        label={CreateLabels.form.downpaymentPercentage}
                        validations={[
                            BronsonFormValidationsEnum.required,
                            BronsonFormValidationsEnum.isNumber,
                        ]}
                        defaultValue={downpaymentPercentage}
                        maxLength={two}
                        onChange={(event) => setDownpaymentInfo(+event.value)}
                        hasError={invalidPercentage}
                        errorMessage={setErrorMsg()}
                        isDisabled={summitDisabled || altDisabled}
                    >
                        <span className='c-input__addon'>%</span>
                    </BronsonFormInput>
                </div>
            </div>
            {altDisabled ? (
                spinnerComponent
            ) : (
                <div className='o-layout__item u-1/1 u-pt-small u-p-none'>
                    <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s u-p-none'>
                        <BronsonFormInput
                            elementId='downpayment-amount-input'
                            label={CreateLabels.form.downpaymentAmount}
                            wrapperClassModifier='c-input--reversed'
                            isDisabled={true}
                            maxLength={maxInput}
                            defaultValue={selectedCalculation.downPaymentAmount}
                        >
                            <span className='c-input__addon'>$</span>
                        </BronsonFormInput>
                    </div>
                    <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s '>
                        <BronsonFormInput
                            elementId='monthly-amount-input'
                            wrapperClassModifier='c-input--reversed'
                            label={CreateLabels.form.monthlyAmount}
                            isDisabled={true}
                            maxLength={maxInput}
                            defaultValue={selectedCalculation.monthlyAmount}
                        >
                            <span className='c-input__addon'>$</span>
                        </BronsonFormInput>
                    </div>
                </div>
            )}
        </>
    );

    const vinInput = (
        <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s u-pl-none u-pt-small'>
            <BronsonFormInput
                elementId='vin-input'
                defaultValue={vin}
                label={CreateLabels.form.vin}
                autoFill={false}
                maxLength={maxImage}
                validations={[BronsonFormValidationsEnum.required]}
                onChange={(event) => setVin(event.value)}
                isDisabled={summitDisabled || altDisabled}
            />
        </div>
    );

    const bookingAmountInput = (
        <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s u-pl-none u-pt-small'>
            <BronsonFormInput
                elementId='booking-amount-input'
                defaultValue={bookingAmount}
                wrapperClassModifier='c-input--reversed'
                label={CreateLabels.form.bookingAmount}
                autoFill={false}
                minLength={one}
                maxLength={maxInput}
                onChange={(event) => validateBookingAmount(event.value)}
                validations={[BronsonFormValidationsEnum.required, BronsonFormValidationsEnum.isCurrency]}
                isDisabled={summitDisabled || altDisabled}
                hasError={invalidBookingAmount}
                errorMessage={setPriceErrorMsg()}
            >
                <span className='c-input__addon'>$</span>
            </BronsonFormInput>
        </div>
    );

    return (
        <div className='o-layout__item u-1/1 u-p-none' id='create-storefront'>
            <div className='o-layout__item u-1/1 u-p-none'>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s u-p-none'>{selectType}</div>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s'>{SelectBrand}</div>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s'>
                    {loadingVehicleModels ? spinnerComponent : selectVehicleModels}
                </div>
            </div>
            <div className='o-layout__item u-1/1 u-pt-small u-pl-none'>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s u-p-none'>
                    {loadingVehicleModels ? spinnerComponent : selectYear}
                </div>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s'>
                    {loadingVehicleModels ? spinnerComponent : selectVersion}
                </div>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s'>
                    {loadingVehicleModels ? spinnerComponent : selectDealer}
                </div>
            </div>
            <div className='o-layout__item u-1/1 u-pt-small u-pl-none'>
                <div className='o-layout__item u-1/3 u-1/1@m u-1/1@s u-p-none'>
                    <BronsonFormInput
                        elementId='price-input'
                        defaultValue={selectedTrimDetail.retailPrice}
                        label={CreateLabels.form.price}
                        wrapperClassModifier='c-input--reversed'
                        maxLength={maxId}
                        isDisabled={kind === StorefrontKind.new || summitDisabled || altDisabled}
                        onChange={(event) => setSelectedPrice(+event.value)}
                        hasError={invalidPrice}
                        validations={[
                            BronsonFormValidationsEnum.required,
                            BronsonFormValidationsEnum.isCurrency,
                        ]}
                        errorMessage={setPriceErrorMsg()}
                    >
                        <span className='c-input__addon'>$</span>
                    </BronsonFormInput>
                </div>
                <div className='o-layout__item u-2/3 u-1/1@m u-1/1@s'>
                    <BronsonFormInput
                        elementId='image-link-input'
                        defaultValue={selectedVehicleData.imageUrl || null}
                        label={CreateLabels.form.image}
                        autoFill={false}
                        maxLength={maxImage}
                        validations={[BronsonFormValidationsEnum.required]}
                        onChange={(event) => setImageUrl(event.value)}
                        isDisabled={summitDisabled || altDisabled}
                    />
                </div>
                {selectedType === types[two] ? bookingAmountInput : <></>}
                {selectedType === types[five] ? vinInput : <></>}
            </div>
            {financialProducts.length > one ? financialProductSection : <></>}
            {selectedType === types[two] ? (
                <CustomerForm customerForm={customerData} setCustomerInfo={setCustomerInfo} />
            ) : (
                <></>
            )}
            <div className='o-layout u-mt-small'>
                <div className='o-layout__item u-1/4 u-1/1@m u-1/1@s'>
                    <span style={{ color: 'red' }}>*</span> Mandatory fields
                </div>
                <div className='o-layout__item u-1/4 u-1/1@m u-1/1@s'></div>
                <div className='o-layout__item u-1/4 u-1/1@m u-1/1@s'>
                    <BronsonButton
                        label={CreateLabels.buttons.clear}
                        dataComponent='clear-btn'
                        click={setClear}
                        config={{
                            isDisable: false,
                            types: ['isSecondary', 'isFull'],
                        }}
                    />
                </div>
                <div className='o-layout__item u-1/4 u-1/1@m u-1/1@s'>
                    {loadingSummit ? (
                        spinnerComponent
                    ) : (
                        <BronsonButton
                            label={CreateLabels.buttons.summit}
                            dataComponent='summit-btn'
                            click={setSummit}
                            config={{
                                isDisable:
                                    summitDisabled || altDisabled || invalidPrice || invalidBookingAmount,
                                types: ['isPrimary', 'isFull'],
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
