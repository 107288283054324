import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Authorizer } from '../Views/Authorizer';
import { BronsonApp } from '../components/BronsonApp';
import { Home } from '../Views/Home';

export const AppRouter = () => {
    const getUser = () => localStorage.getItem('user');
    const [user] = useState(getUser());

    useEffect(() => {
        if (!user) {
            window.location.href = '#/auth';
        } else {
            window.location.href = '#/home';
        }
    });

    return (
        <Router basename='/'>
            <BronsonApp userData={user}>
                <Switch>
                    <Route path='/auth' component={Authorizer} />
                    <Route path='/home' component={Home} />
                </Switch>
            </BronsonApp>
        </Router>
    );
};
