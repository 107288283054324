import {
    BronsonDatePicker,
    BronsonFormInput,
    BronsonFormValidationsEnum,
    BronsonLanguages,
} from '@dh/bronson-react';

import { CustomerData } from '../../utils/interface';
import { customerFormLabels } from './customer-form.labels';
import { maxEmail, maxInput, ten } from '../../utils/const';

import React from 'react';

export const CustomerForm: React.FC<{
    customerForm: CustomerData;
    setCustomerInfo: (value: string, field: string) => void;
}> = (props) => {
    const { customerForm, setCustomerInfo } = props;

    return (
        <div className='o-layout__item u-1/1 u-p-none' id='customer-form'>
            <h3 className='u-mt'>{customerFormLabels.title}</h3>
            <div className='o-layout__item u-1/1 u-pl-none u-pt-small'>
                <div className='o-layout__item u-1/3 u-1/2@m u-1/1@s u-p-none'>
                    <BronsonFormInput
                        defaultValue={customerForm.firstName}
                        elementId='first-name-input'
                        label={customerFormLabels.form.firstName}
                        maxLength={maxInput}
                        onChange={(event) => setCustomerInfo(event.value, 'firstName')}
                        language={BronsonLanguages.ENG}
                        validations={[BronsonFormValidationsEnum.required]}
                    />
                </div>
                <div className='o-layout__item u-1/3 u-1/2@m u-1/1@s'>
                    <BronsonFormInput
                        defaultValue={customerForm.middleNames || ''}
                        elementId='middle-name-input'
                        label={customerFormLabels.form.middleName}
                        maxLength={maxInput}
                        onChange={(event) => setCustomerInfo(event.value, 'middleNames')}
                    />
                </div>
                <div className='o-layout__item u-1/3 u-1/2@m u-1/1@s'>
                    <BronsonFormInput
                        defaultValue={customerForm.paternalName}
                        elementId='paternal-name-input'
                        label={customerFormLabels.form.paternalName}
                        maxLength={maxInput}
                        onChange={(event) => setCustomerInfo(event.value, 'paternalName')}
                        language={BronsonLanguages.ENG}
                        validations={[BronsonFormValidationsEnum.required]}
                    />
                </div>
            </div>
            <div className='o-layout__item u-1/1 u-pl-none u-pt-small'>
                <div className='o-layout__item u-1/3 u-1/2@m u-1/1@s u-p-none'>
                    <BronsonFormInput
                        defaultValue={customerForm.maternalName || ''}
                        elementId='maternal-name-input'
                        label={customerFormLabels.form.maternalName}
                        maxLength={maxInput}
                        onChange={(event) => setCustomerInfo(event.value, 'maternalName')}
                    />
                </div>
                <div className='o-layout__item u-1/3 u-1/2@m u-1/1@s'>
                    <label>
                        {customerFormLabels.form.dateOfBirth}
                        <span style={{ color: 'red' }}> *</span>
                    </label>
                    <BronsonDatePicker
                        className='date-test-class'
                        value={customerForm.dateOfBirth}
                        locale='es'
                        dateFormat={'Y-m-d'}
                        testId={'birthday-input'}
                        onChange={(value) => setCustomerInfo(value.toString(), 'dateOfBirth')}
                    />
                </div>
                <div className='o-layout__item u-1/3 u-1/2@m u-1/1@s'>
                    <BronsonFormInput
                        defaultValue={customerForm.contactData.email}
                        elementId='email-input'
                        label={customerFormLabels.form.email}
                        maxLength={maxEmail}
                        autoFill={false}
                        validations={[
                            BronsonFormValidationsEnum.isEmail,
                            BronsonFormValidationsEnum.required,
                        ]}
                        onChange={(event) => setCustomerInfo(event.value, 'email')}
                        language={BronsonLanguages.ENG}
                    />
                </div>
            </div>
            <div className='o-layout__item u-1/1 u-pl-none u-pt-small'>
                <div className='o-layout__item u-1/4 u-1/2@m u-1/1@s u-p-none'>
                    <BronsonFormInput
                        defaultValue={customerForm.contactData.telephoneNumber}
                        elementId='phone-input'
                        label={customerFormLabels.form.telephoneNumber}
                        maxLength={ten}
                        minLength={ten}
                        autoFill={false}
                        validations={[
                            BronsonFormValidationsEnum.isOnlyDigits,
                            BronsonFormValidationsEnum.required,
                        ]}
                        language={BronsonLanguages.ENG}
                        onChange={(event) => setCustomerInfo(event.value, 'telephoneNumber')}
                    />
                </div>
            </div>
        </div>
    );
};
